import React from 'react';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import star from '../../assets/images/base/star.png'
import starGreen from '../../assets/images/base/star-green.png'

const useStyles = makeStyles((theme) => ({
  star: {
    position: 'absolute',
    margin: '13px 0px 0px 20px',
    width: '50px',
    [theme.breakpoints.down('md')]: {
      width: '40px',
    },
    '&::selection': {
      backgroundColor: '#666'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
}));

export default function Default() {
  const classes = useStyles();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
    <img className={classes.star} src={star} alt="Demo Moving Image" onClick={scrollToTop}/>  
    </>
  );
}

export function StarArticle() {
  const classes = useStyles();

  return (
    <>
    <img className={classes.star} 
      src={star} alt="Demo Moving Image" 
      onClick={() => navigate(-1)}/>  
    </>
  );
}
export function StarGreen() {
  const classes = useStyles();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
    <img className={classes.star} src={starGreen} alt="Demo Moving Image" onClick={scrollToTop}/>  
    </>
  );
}