import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { StarArticle } from '../components/sections/Star'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#d6d7d6",
  },
  header: {
    padding: "130px 50px 50px 250px",
    [theme.breakpoints.down('lg')]: {
      padding: '130px 50px 15px 200px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '130px 50px 15px 50px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '130px 30px 15px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '130px 20px 15px 20px',
    },
  },
  content: {
    padding: "50px 250px 50px 250px",
    [theme.breakpoints.down('lg')]: {
      padding: '50px 150px 50px 200px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '50px 50px 50px 50px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 30px 50px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '50px 20px 50px 20px',
    },
  },
  title: {
    lineHeight: '80%',
  },
  subtitle: {
    lineHeight: '80%',
    fontFamily: 'FreightBigBook',
  }, 
  divider: {
    borderTop: '2px solid #00FF00',
  }
}));

export default function Album({url, title, subtitle, children}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <StarArticle/>
    <div className={classes.header}>
      <Grid container>
        <Grid item xs={12}>
        <Typography variant='h3'
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography variant='h3'
         className={classes.subtitle}
        >
          {subtitle}
        </Typography>
        </Grid>
      </Grid>
      </div>
      <div className={classes.divider}></div>
        <div className={classes.content}>
        <Grid container>
        <Grid item xs={12} sm={10} lg={8}
          
        >
          {children}    
          </Grid>
          </Grid>
          </div>
      </div>
  );
}